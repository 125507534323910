import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Bot, Target, ClipboardList, TrendingUp, Workflow, Sigma } from "lucide-react";

const ServiceItem = ({ icon: Icon, name, content }) => (
  <div className="bg-white dark:bg-[#1E2730] p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg m-2">
    <Icon className="text-primary mb-4 mx-auto" size={50} />
    <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white text-center">
      {name}
    </h3>
    <p className="text-gray-600 dark:text-gray-300 text-center">{content}</p>
  </div>
);

const Services = ({ darkMode }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const particles = [];

    for (let i = 0; i < 50; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        dx: (Math.random() - 0.5) * 0.5,
        dy: (Math.random() - 0.5) * 0.5,
      });
    }

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = darkMode ? 'rgba(237, 66, 81, 0.5)' : 'rgba(237, 66, 81, 0.2)';
      ctx.strokeStyle = darkMode ? 'rgba(237, 66, 81, 0.2)' : 'rgba(237, 66, 81, 0.1)';
      ctx.lineWidth = 1;

      particles.forEach((particle, i) => {
        particle.x += particle.dx;
        particle.y += particle.dy;

        if (particle.x < 0 || particle.x > canvas.width) particle.dx *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.dy *= -1;

        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fill();

        particles.slice(i + 1).forEach(otherParticle => {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 100) {
            ctx.beginPath();
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });

      animationFrameId = requestAnimationFrame(drawParticles);
    };

    drawParticles();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, [darkMode]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const services = [
    {
      icon: Bot,
      name: "Asistentes de IA",
      content:
        "Asistentes de IA personalizados que permiten la automatización de procesos y facilitan la interacción con grandes volúmenes de datos.",
    },
    {
      icon: Target,
      name: "Modelado predictivo",
      content:
        "Utilizamos datos y algoritmos avanzados para analizar tendencias, optimizar procesos y obtener predicciones clave para tu negocio.",
    },
    {
      icon: ClipboardList,
      name: "Reportería automática",
      content:
        "Recibe información relevante y personalizada de tu negocio a través de correo electrónico y mensajería instantánea (WhatsApp).",
    },
    {
      icon: TrendingUp,
      name: "Inteligencia de negocios",
      content:
        "Diseñamos tableros que son fáciles de comprender y te ayudamos a desarrollar indicadores para monitorear tu negocio.",
    },
    {
      icon: Workflow,
      name: "ETL",
      content:
        "Automatizamos la extracción, transformación y carga de datos para que siempre tengas información fidedigna y accesible.",
    },
    {
      icon: Sigma,
      name: "Consultoría estadística",
      content:
        "Te ayudamos a planificar ensayos, interpretar los datos y convertirlos en estrategias efectivas para mejorar los resultados de tu negocio.",
    },
  ];

  return (
    <section
      id="services"
      className="py-16 bg-gray-50 dark:bg-[#151C24] transition-colors duration-300 relative overflow-hidden"
    >
      <canvas
        ref={canvasRef}
        className="absolute top-0 left-0 w-full h-full"
        style={{ opacity: 0.7 }}
      />
      <div className="pt-24 container mx-auto px-4 relative z-10">
        <h2 className="text-5xl font-bold text-center mb-12 text-gray-800 dark:text-white">
          Nuestros Servicios
        </h2>
        <Slider {...settings}>
          {services.map((service, index) => (
            <ServiceItem
              key={index}
              icon={service.icon}
              name={service.name}
              content={service.content}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Services;