import React from "react";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductVideo = ({ name, videoUrl, lightImage, link }) => {
  const playIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="80"
      height="80"
      fill="#ED4251"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        padding: "10px",
      }}
    >
      <path d="M8 5v14l11-7z" />
    </svg>
  );

  return (
    <div className="px-2">
      <div className="bg-white dark:bg-[#1E2730] rounded-lg shadow-lg overflow-hidden">
        <ReactPlayer
          url={videoUrl}
          width="100%"
          height="300px"
          controls
          light={lightImage}
          playIcon={playIcon}
        />
        <div className="p-4">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl font-semibold text-[#ED4251] hover:text-[#ff5564] transition-colors duration-300"
          >
            {name}
          </a>
        </div>
      </div>
    </div>
  );
};

const Products = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Lista de productos con sus videos y miniaturas personalizadas
  const products = [
    {
      name: "Artemisa",
      videoUrl: "/videos/artemisa.mp4",
      lightImage: "/images/artemisa.png",
      link: "https://artemisa-restec.com/",
    },
    {
      name: "Cereza.IA",
      videoUrl: "/videos/cereza-ia.mp4",
      lightImage: "/images/cereza.png",
      link: "",
    },
    {
      name: "Delfos",
      videoUrl: "/videos/delfos.mp4",
      lightImage: "/images/delfos.png",
      link: "",
    },
    {
      name: "Luxia",
      videoUrl: "/videos/luxia.mp4",
      lightImage: "/images/luxia.png",
      link: "",
    },
    {
      name: "Porkus",
      videoUrl: "/videos/porkus.mp4",
      lightImage: "/images/porkus.png",
      link: "",
    },
    {
      name: "Precios café",
      videoUrl: "/videos/precios-cafe.mp4",
      lightImage: "/images/precios-cafe.png",
      link: "",
    },
  ];

  return (
    <section
      id="products"
      className="py-16 bg-gray-50 dark:bg-[#151C24] transition-colors duration-300"
    >
      <div className="pt-24 container mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-12 text-gray-800 dark:text-white">
          Nuestros Productos
        </h2>
        <Slider {...settings}>
          {products.map((product, index) => (
            <ProductVideo
              key={index}
              name={product.name}
              videoUrl={product.videoUrl}
              lightImage={product.lightImage}
              link={product.link}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Products;
