import React, { useEffect, useRef, useState } from "react";
import { Menu, X } from "lucide-react";
import InfinitySymbol from "./InfinitySymbol";
import Services from "./Services";
import Products from "./Products";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Footer from "./Footer";

const ResTecLandingPage = () => {
  const canvasRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Detector de dispositivo móvil
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = document.documentElement.scrollHeight;
    };

    const drawNeuralNetwork = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 0.5;

      const nodes = [];
      // Mantenemos los 500 nodos originales
      for (let i = 0; i < 500; i++) {
        nodes.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          vx: (Math.random() - 0.5) * 2,
          vy: (Math.random() - 0.5) * 2,
        });
      }

      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = "rgba(85, 99, 121, 0.5)";

        nodes.forEach((node, i) => {
          node.x += node.vx;
          node.y += node.vy;

          if (node.x < 0 || node.x > canvas.width) node.vx *= -1;
          if (node.y < 0 || node.y > canvas.height) node.vy *= -1;

          ctx.beginPath();
          ctx.arc(node.x, node.y, 2, 0, Math.PI * 2);
          ctx.fillStyle = "rgba(237, 66, 81, 0.8)";
          ctx.fill();

          nodes.slice(i + 1).forEach((otherNode) => {
            const dx = otherNode.x - node.x;
            const dy = otherNode.y - node.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < 100) {
              ctx.beginPath();
              ctx.moveTo(node.x, node.y);
              ctx.lineTo(otherNode.x, otherNode.y);
              ctx.stroke();
            }
          });
        });

        animationFrameId = requestAnimationFrame(animate);
      };

      animate();
    };

    // Mantenemos la animación en todos los dispositivos
    window.addEventListener("resize", resizeCanvas);
    window.addEventListener("scroll", resizeCanvas);
    resizeCanvas();
    drawNeuralNetwork();

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      window.removeEventListener("scroll", resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const NavLinks = () => (
    <ul className={`${isMobile ? 
      'flex flex-col space-y-4 items-center w-full' : 
      'flex space-x-6 items-center'}`}>
      <li>
        <a href="#" className="text-text-dark hover:text-primary transition-colors">
          ResTec
        </a>
      </li>
      <li>
        <a href="#services" className="text-text-dark hover:text-primary transition-colors">
          Servicios
        </a>
      </li>
      <li>
        <a href="#products" className="text-text-dark hover:text-primary transition-colors">
          Productos
        </a>
      </li>
      <li>
        <a href="#about-us" className="text-text-dark hover:text-primary transition-colors">
          Nosotros
        </a>
      </li>
      <li>
        <a href="#contact" className="text-text-dark hover:text-primary transition-colors">
          Contacto
        </a>
      </li>
    </ul>
  );

  return (
    <div className="min-h-screen dark">
      <div className="min-h-screen bg-background-dark text-text-dark font-sans transition-colors duration-300">
        <canvas
          ref={canvasRef}
          className="absolute inset-0 z-0"
          style={{ backgroundColor: "#151C24" }}
        />
        <div className="relative z-10">
          <header className="fixed top-0 left-0 right-0 z-50 p-4 md:p-6 bg-background-dark bg-opacity-90 backdrop-blur-sm transition-colors duration-300">
            <nav className="flex flex-col md:flex-row justify-between items-center">
              <div className="w-full md:w-auto flex justify-between items-center">
                <h1 className="text-xl md:text-2xl font-bold text-primary">
                  Resilient Technologies
                </h1>
                {isMobile && (
                  <button
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="text-text-dark hover:text-primary"
                  >
                    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                  </button>
                )}
              </div>
              
              {isMobile ? (
                <div className={`${isMenuOpen ? 'flex' : 'hidden'} w-full mt-4`}>
                  <NavLinks />
                </div>
              ) : (
                <NavLinks />
              )}
            </nav>
          </header>
          
          <main className="pt-24 container mx-auto px-6 py-12">
            <section className="text-center mb-16">
              <h2 className={`text-7xl font-bold mb-4 transition-all duration-1000 transform ${
                isLoaded ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
              } text-[var(--text-dark)]`}>
                Inteligencia en cada bit
              </h2>
              <InfinitySymbol />
              <p className={`text-lg md:text-2xl mb-4 transition-all duration-1000 delay-300 transform ${
                isLoaded ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
              } text-[var(--text-dark)] px-4 md:px-0`}>
                Transformando el futuro con datos e inteligencia artificial.
                Creemos en las soluciones tecnológicas que desafían los límites de lo posible.
              </p>
              <button className={`bg-primary text-white px-4 md:px-6 py-2 md:py-3 rounded-full hover:bg-opacity-80 transition-colors transition-all duration-1000 delay-600 transform ${
                isLoaded ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
              }`}>
                <a href="#services">Descubre Nuestras Soluciones</a>
              </button>
            </section>
            
            <Services />
            <Products />
            <AboutUs />
            <Contact />
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ResTecLandingPage;