import React, { useState, useEffect } from 'react';

const InfinitySymbol = ({ darkMode }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [dashOffset, setDashOffset] = useState(0);

  // Animación para el desplazamiento del círculo "chispa"
  useEffect(() => {
    let animationFrameId;
    const animateDashOffset = () => {
      setDashOffset((prevOffset) => (prevOffset + 2) % 300); // Ajustar la velocidad aquí
      animationFrameId = requestAnimationFrame(animateDashOffset);
    };
    animateDashOffset();
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  return (
    <div className="flex justify-center items-center">
      <svg
        width="500"
        height="190"
        viewBox="0 0 200 100"
        className="transition-all duration-300 ease-in-out"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Símbolo del infinito */}
        <path
          d="M50,50 C50,30 70,30 100,50 C130,70 150,70 150,50 C150,30 130,30 100,50 C70,70 50,70 50,50 Z"
          fill="none"
          stroke={isHovered ? "#ED4251" : "#556379"} // Cambia el color al pasar el mouse
          strokeWidth="8"
          strokeLinecap="round"
          className="transition-all duration-300 ease-in-out"
          filter={isHovered ? 'drop-shadow(0px 0px 10px rgba(237, 66, 81, 0.8))' : ''} // Resplandor
        />

        {/* Círculo de luz/chispa que se mueve a lo largo del símbolo */}
        <circle
          cx="0"
          cy="0"
          r="4"
          fill="#ED4251"
          stroke="none"
          className="animate-move-light"
        >
          <animateMotion
            dur="5s" // Duración del ciclo de la chispa
            repeatCount="indefinite"
          >
            <mpath href="#infinityPath" />
          </animateMotion>
        </circle>

        {/* Caminos de "dash" animado */}
        <path
          d="M50,50 C50,30 70,30 100,50 C130,70 150,70 150,50 C150,30 130,30 100,50 C70,70 50,70 50,50 Z"
          id="infinityPath" // Referencia para la animación de la chispa
          fill="none"
          stroke="none"
          strokeDasharray="300"
          strokeDashoffset={dashOffset}
        />
      </svg>
    </div>
  );
};

export default InfinitySymbol;
