import React from "react";
import { Atom, Lightbulb, Scale } from "lucide-react";

const AboutUs = ({ darkMode }) => (
  <section
    id="about-us"
    className="bg-white dark:bg-[#151C24] transition-colors duration-300"
  >
    <div className="pt-24 container mx-auto px-4">
      <h2 className="text-5xl font-bold text-center mb-12 text-white">
        Sobre Nosotros
      </h2>
      <section className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {[
          {
            icon: Atom,
            title: "Pasión",
            content:
              "En ResTec, la ciencia es nuestra pasión. Investigamos para entender desafíos complejos, descubrimos nuevas oportunidades y creamos soluciones innovadoras que permiten la gestión estratégica de la toma de decisiones con la finalidad de optimizar procesos.",
          },
          {
            icon: Lightbulb,
            title: "Curiosidad",
            content:
              "En ResTec, somos curiosos y pragmáticos en la búsqueda de soluciones innovadoras. Creamos productos que dan respuesta a necesidades específicas. Con una perspectiva holística desarrollamos estrategias efectivas que impulsan el crecimiento y el éxito a largo plazo.",
          },
          {
            icon: Scale,
            title: "Transparencia",
            content:
              "En ResTec, los datos son nuestra guía. Recopilamos, analizamos e interpretamos información para contribuir en la gestión de la toma de decisiones. Creemos en la integridad, seguridad y la transparencia de datos para construir sistemas de IA éticos.",
          },
        ].map(({ icon: Icon, title, content }, index) => (
          <div
            key={index}
            className="bg-gray-100 dark:bg-[#1E2730] p-6 rounded-lg shadow-md transition-colors duration-300"
          >
            <div className="flex justify-center">
              <Icon className="text-primary mb-4" size={70} />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white text-center">
              {title}
            </h3>
            <p className="text-gray-600 dark:text-gray-300 text-center">
              {content}
            </p>
          </div>
        ))}
      </section>
    </div>
  </section>
);

export default AboutUs;
